<template>
  <v-card
    flat
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <b10-text>
            {{ item.descripcion }}<span v-if="item.requerido"> (requerido)</span>
          </b10-text>
        </v-list-item-title>
        <v-list-item-subtitle
          v-show="item.idvigilante"
        >
          <b10-text>{{ item.nombre_vigilante }}</b10-text>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-subtitle
      class="py-1"
    >
      <v-row
        no-gutters
        class="d-flex justify-space-between"
      >
        <div
          class="d-flex align-center"
        >
          <v-btn-toggle
            v-model="resultado"
            borderless
          >
            <v-btn
              icon
              color="white"
            >
              <v-icon
                :color="colorBotonMal"
              >
                {{ $vuetify.icons.values.psCheckMal }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
            >
              <v-icon
                :color="colorBotonOk"
              >
                {{ $vuetify.icons.values.psCheckOk }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
            >
              <v-icon
                :color="colorBotonNoProcede"
              >
                {{ $vuetify.icons.values.psCheckNoProcede }}
              </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            borderless
          >
            <v-badge
              v-model="conObservaciones"
              overlap
              dot
              color="red"
            >
              <v-btn
                icon
                color="white"
                @click="clickObservaciones"
              >
                <v-icon
                  color="grey"
                >
                  {{ $vuetify.icons.values.chat }}
                </v-icon>
              </v-btn>
            </v-badge>
          </v-btn-toggle>
          <v-btn-toggle
            borderless
          >
            <v-badge
              v-model="conAdjuntos"
              overlap
              dot
              color="red"
            >
              <v-btn
                icon
                color="white"
                @click="clickAdjuntos"
              >
                <v-icon
                  color="grey"
                >
                  {{ $vuetify.icons.values.attach }}
                </v-icon>
              </v-btn>
            </v-badge>
          </v-btn-toggle>
        </div>
      </v-row>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { PS_CHECK } from '@/utils/consts'
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    punto: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      PS_CHECK,
    }
  },
  computed: {
    resultado: {
      get () {
        return this.item.resultado
      },
      set (value) {
        this.$emit('change-resultado', {
          item: this.item,
          punto: this.punto,
          resultado: value,
        })
      },
    },
    conObservaciones: {
      get () {
        return !!this.item.observaciones
      },
      set () {
        // no hacer nada, pero evitar error "has no setter" -> esta como v-model del badge
      },
    },
    conAdjuntos: {
      get () {
        return this.item.con_adjuntos
      },
      set () {
        // no hacer nada, pero evitar error "has no setter" -> esta como v-model del badge
      },
    },
    colorBotonOk () {
      if (this.item.resultado === PS_CHECK.resultado.ok) {
        return PS_CHECK.colorResultado.ok
      } else {
        return colors.grey.base
      }
    },
    colorBotonMal () {
      if (this.item.resultado === PS_CHECK.resultado.mal) {
        return PS_CHECK.colorResultado.mal
      } else {
        return colors.grey.base
      }
    },
    colorBotonNoProcede () {
      if (this.item.resultado === PS_CHECK.resultado.noProcede) {
        return PS_CHECK.colorResultado.noProcede
      } else {
        return colors.grey.base
      }
    },
  },
  methods: {
    clickObservaciones () {
      this.$emit('click-observaciones', { item: this.item, punto: this.punto })
    },
    clickAdjuntos () {
      this.$emit('click-adjuntos', { item: this.item, punto: this.punto })
    }
  },
}
</script>
